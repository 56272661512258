<template>
  <section class="page-contact page-container page-bg">
    <h2>Contact</h2>
    <iframe ref="iframe" id="myIframe" src="https://docs.google.com/forms/d/e/1FAIpQLSc6A9qRMDv7aHz7QjRPItMyeGSLc6kdXF9TB305xC2vZpwBzQ/viewform?embedded=true" width="100%" height="1300" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます...</iframe>
<!--    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">-->
<!--      <el-form-item label="名前：" prop="name">-->
<!--        <el-input v-model="ruleForm.name"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="連絡先：" prop="tel">-->
<!--        <el-input v-model="ruleForm.tel"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="メールアドレス：" prop="email">-->
<!--        <el-input v-model="ruleForm.email"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="会社名：">-->
<!--        <el-input v-model="ruleForm.company"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="お問い合わせ内容：">-->
<!--        <el-input type="textarea" resize="none" v-model="ruleForm.content"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <div class="submit-btn" @click="submitForm('ruleForm')">Submit</div>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
  </section>
</template>

<script>
export default {
  name: 'contact',
  data() {
    return {
      ruleForm: {
        name: '',
        tel: '',
        email: '',
        company: '',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '名前', trigger: 'blur' },
        ],
        tel: [
          { required: true, message: '連絡先', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'メールアドレス', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const iframeDocument = this.$refs.iframe.contentDocument || this.$refs.iframe.contentWindow.document;
      if (iframeDocument) {
        console.error('无法访问iframe内容，因为它跨域了。');
        return;
      }

      // 找到需要隐藏的元素
      const elementToHide1 = iframeDocument.querySelector('.Oh1Vtf');
      const elementToHide4 = iframeDocument.querySelector('.zAVwcb');
      const elementToHide2 = iframeDocument.querySelector('.T2dutf');
      const elementToHide3 = iframeDocument.querySelector('.xpWfed');

      // 隐藏元素
      if (elementToHide1) {
        elementToHide1.style.display = 'none';
      }
      if (elementToHide2) {
        elementToHide2.style.display = 'none';
      }
      if (elementToHide3) {
        elementToHide3.style.display = 'none';
      }
      if (elementToHide4) {
        elementToHide4.style.display = 'none';
      }
    });
    this.$nextTick(()=>{
      this.setIframeHeight('myIframe')
    })
  },
  methods: {
    setIframeHeight(iframeId){
      let cwin = document.getElementById(iframeId);
      if (document.getElementById){
        if (cwin && !window.opera){
          if (cwin.contentDocument && cwin.contentDocument.body.offsetHeight){
            cwin.height = cwin.contentDocument.body.offsetHeight + 20; //FF NS
          }
          else if(cwin.Document && cwin.Document.body.scrollHeight){
            cwin.height = cwin.Document.body.scrollHeight + 10;//IE
          }
        }else{
          if(cwin.contentWindow.document && cwin.contentWindow.document.body.scrollHeight)
            cwin.height = cwin.contentWindow.document.body.scrollHeight;//Opera
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$Axios({
            url: `/v1/mail/send`,
            method: 'post',
            data: {
              "name": this.ruleForm.name,
              "phone": this.ruleForm.tel,
              "email": this.ruleForm.email,
              "company": this.ruleForm.company,
              "content": this.ruleForm.content
            },
          }).then((res) => {
            this.$message.success({message: res, duration: '2000',})
          }).catch(() => {})
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.page-contact {
  //height: 1390px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgba(239, 235, 247, 1) url('../assets/images/grid_bg.png') no-repeat -20px 150px;
  background-size: 124% 80%;
}
h2 {
  font-weight: 500;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 140px;
  color: rgba(47, 41, 223, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 20px;
}
.el-form {
  padding-left: 100px;
  .el-form-item {
    display: flex;
    margin-bottom: 30px;
  }
  /deep/ .el-form-item__label {
    width: 290px!important;
    flex-shrink: 0;
    margin-right: 40px;
    font-size: 20px;
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: 50px;
    color: rgba(60, 60, 60, 1);
  }
  /deep/ .el-form-item__content .el-input, /deep/ .el-form-item__content .el-input__inner {
    width: 560px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    color: rgba(60, 60, 60, 1);
  }
  /deep/ .el-form-item__error {
    font-size: 18px;
  }
  .el-textarea /deep/ .el-textarea__inner {
    width: 560px;
    height: 280px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    font-size: 20px;
    color: rgba(60, 60, 60, 1);
    font-weight: 400;
  }
  .el-form-item.is-required:not(.is-no-asterisk) /deep/ .el-form-item__label:before {
    display: none;
  }
}
.submit-btn {
  width: 571px;
  height: 64px;
  opacity: 1;
  border-radius: 18px;
  background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 64px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
  cursor: hand;
  margin-left: 330px;
}
.Oh1Vtf,.T2dutf,.xpWfed,.zAVwcb {
  display: none!important;
}
</style>
